import Chart from "chart.js/auto";
import i18n from '@/plugins/i18n'

const max_value = 4; // Maximaler Wert im Diagramm

const weights_s = {
  categorie_personal_data: 0.2,
  special_categorie_personal_data: 0.3,
  categorie_data_subject: 0.1,
  third_country: 0.4,
};

const weights_p = {
  third_country: 0.70,
  delete_period: 0.30,
};

function calc(data, weight, risk_attr) {
  const sum = data.reduce((partialSum, a) => partialSum + a[risk_attr], 0);
  return sum * weight;
}

function calc_risk(data, weight_dict, weight_name, risk_attr) {
  return {
    value: calc(data, weight_dict[weight_name], risk_attr),
    max: data.length * weight_dict[weight_name],
  };
}

function calc_third_country(data, weight_dict, weight_name) {
  let third_country_receivers = 0
  let receivers_with_appropriate_safeguards = 0

  data.forEach((receiver) => {
    let location_value = receiver.storage_locations.some((location) => {
      return !(location.location.eea || location.location.secure_third_country)
    });

    if (location_value || !(receiver.external_data_receiver.location.country.eea || receiver.external_data_receiver.location.country.secure_third_country)) {
      third_country_receivers++;

      // Check if appropriate safeguards available for the receiver
      /*if (receiver.appropriate_safeguards.length > 0) {
        receivers_with_appropriate_safeguards++;
      }*/
    }
  })

  return {
    value: (third_country_receivers - receivers_with_appropriate_safeguards) * max_value * weight_dict[weight_name],
    max: third_country_receivers * weight_dict[weight_name],
  };
}

function calc_measures(data, risk_attr) {
  if (data.length <= 0) {
    return 0;
  }
  return data.reduce((partialSum, a) => partialSum + a[risk_attr], 0) / data.length / max_value;
}

function calc_deletion_period(activity) {
  let total = activity.categories_personal_data.length + activity.special_categories_personal_data.length;
  let with_deletion = 0;

  activity.categories_personal_data.forEach((data) => {
    if (data.deletion_period.length > 0 && data.repositories.length > 0) {
      with_deletion++;
    }
  })

  activity.special_categories_personal_data.forEach((data) => {
    if (data.deletion_period.length > 0 && data.repositories.length > 0) {
      with_deletion++;
    }
  })

  return {
    value: total == 0 ? 0 : (1 - (with_deletion / total)) * max_value * weights_p["delete_period"],
    max: weights_p["delete_period"]
  };
}

function calculate_gross(data) {
  const categorie_personal_data = calc_risk(data.categories_personal_data, weights_s, "categorie_personal_data", "risk_s")
  const special_categorie_personal_data = calc_risk(data.special_categories_personal_data, weights_s, "special_categorie_personal_data", "risk_s")
  const categorie_data_subject = calc_risk(data.categories_data_subject, weights_s, "categorie_data_subject", "risk_s")
  const third_country_s = calc_third_country(data.external_receiver, weights_s, "third_country")
  const third_country_p = calc_third_country(data.external_receiver, weights_p, "third_country")
  const delete_period = calc_deletion_period(data)

  const s_value =
    categorie_personal_data.value +
    special_categorie_personal_data.value +
    categorie_data_subject.value +
    third_country_s.value;

  const s_max =
    (categorie_personal_data.max +
      special_categorie_personal_data.max +
      categorie_data_subject.max +
      third_country_s.max) *
    max_value;

  const p_value =
    third_country_p.value +
    delete_period.value

  const p_max = (third_country_p.max +
    delete_period.max) *
    max_value;

  // Bruttorisiko
  const gross_risk = {
    s: s_max <= 0 ? 0 : s_value / s_max * max_value,
    p: p_max <= 0 ? 0 : p_value / p_max * max_value,
  }

  return gross_risk;
}

function calculate_processing_activity(activity) {
  const gross_risk = calculate_gross(activity);

  // Bewertung spezifischer und allgemeiner Maßnahmen zusammenfügen
  const measures = [...activity.measures];
  measures.push({ risk_s: activity.general_measure_risk_s, risk_p: activity.general_measure_risk_p })

  // Verbesserung durch Maßnahmen berechnen
  const improvement_s = 1 - calc_measures(measures, "risk_s")
  const improvement_p = 1 - calc_measures(measures, "risk_p")

  // Nettorisiko
  const net_risk = {
    s: gross_risk.s * improvement_s,
    p: gross_risk.p * improvement_p,
  }

  return {
    gross: gross_risk,
    net: net_risk,
  }
}

function calculate_gross_sources(sources) {
  if (sources.length <= 0)
    return {
      s: 0,
      p: 0,
    }

  const result = sources.reduce((curr, val) => {
    return {
      s: curr.s + (val["risk_s"] || 0),
      p: curr.p + (val["risk_p"] || 0)
    }
  }, {
    s: 0,
    p: 0,
  });

  return {
    s: result.s / sources.length,
    p: result.p / sources.length,
  }
}

function calculate_scenario(scenario) {
  const gross = calculate_gross_sources(scenario.sources);

  // Verbesserung durch Maßnahmen berechnen
  const improvement_s = 1 - calc_measures(scenario.measures, "risk_s")
  const improvement_p = 1 - calc_measures(scenario.measures, "risk_p")

  return {
    gross: gross,
    net: {
      s: gross.s * improvement_s,
      p: gross.p * improvement_p,
    },
  }
}

function calculate_scenarios(scenarios) {
  if (scenarios.length <= 0)
    return {
      gross: {
        s: 0,
        p: 0,
      },
      net: {
        s: 0,
        p: 0,
      }
    }

  const result = scenarios.reduce((curr, val) => {
    const risks = calculate_scenario(val);

    return {
      gross: {
        s: curr.gross.s + risks.gross.s,
        p: curr.gross.p + risks.gross.p
      },
      net: {
        s: curr.net.s + risks.net.s,
        p: curr.net.p + risks.net.p
      }
    }
  }, {
    gross: {
      s: 0,
      p: 0,
    },
    net: {
      s: 0,
      p: 0,
    }
  });

  return {
    gross: {
      s: result.gross.s / scenarios.length,
      p: result.gross.p / scenarios.length,
    },
    net: {
      s: result.net.s / scenarios.length,
      p: result.net.p / scenarios.length,
    }
  }
}

function calculate_impact_assessment(impact_assessment) {
  return calculate_scenarios(impact_assessment.scenarios)
}

function Shape(x, y, w, h, fill) {
  this.x = x;
  this.y = y;
  this.w = w;
  this.h = h;
  this.fill = fill;
}



function create_risk_chart(datasets, ctx, label_callback) {
  const label_y = i18n.t('eintrittswahrscheinlichkeit');
  const label_x = i18n.t('schadensausmass');
  const risk_color_1 = "green";
  const risk_color_2 = "yellow";
  const risk_color_3 = "orange";
  const risk_color_4 = "red";

  const background_canvas_plugin = {
    id: "background_canvas_risk_plugin",
    beforeDraw: (chart) => {
      const { ctx } = chart;
      ctx.save();
      ctx.globalCompositeOperation = "destination-over";

      let areas = [];

      areas.push(
        // Rot oben
        new Shape(
          chart.scales.x.getPixelForValue(0),
          chart.scales.y.getPixelForValue(4),
          chart.scales.x.getPixelForValue(4) -
          chart.scales.x.getPixelForValue(0),
          chart.scales.y.getPixelForValue(3) -
          chart.scales.y.getPixelForValue(4),
          risk_color_4
        ),
        // Rot rechts
        new Shape(
          chart.scales.x.getPixelForValue(3),
          chart.scales.y.getPixelForValue(3),
          chart.scales.x.getPixelForValue(4) -
          chart.scales.x.getPixelForValue(3),
          chart.scales.y.getPixelForValue(0) -
          chart.scales.y.getPixelForValue(3),
          risk_color_4
        ),
        // Gelb oben
        new Shape(
          chart.scales.x.getPixelForValue(0),
          chart.scales.y.getPixelForValue(2),
          chart.scales.x.getPixelForValue(2) -
          chart.scales.x.getPixelForValue(0),
          chart.scales.y.getPixelForValue(1) -
          chart.scales.y.getPixelForValue(2),
          risk_color_2
        ),
        // Gelb rechts
        new Shape(
          chart.scales.x.getPixelForValue(1),
          chart.scales.y.getPixelForValue(0),
          chart.scales.x.getPixelForValue(2) -
          chart.scales.x.getPixelForValue(1),
          chart.scales.y.getPixelForValue(1) -
          chart.scales.y.getPixelForValue(0),
          risk_color_2
        ),
        // Orange oben
        new Shape(
          chart.scales.x.getPixelForValue(0),
          chart.scales.y.getPixelForValue(2),
          chart.scales.x.getPixelForValue(3) -
          chart.scales.x.getPixelForValue(0),
          chart.scales.y.getPixelForValue(2) -
          chart.scales.y.getPixelForValue(1),
          risk_color_3
        ),
        // Orange rechts
        new Shape(
          chart.scales.x.getPixelForValue(2),
          chart.scales.y.getPixelForValue(2),
          chart.scales.x.getPixelForValue(3) -
          chart.scales.x.getPixelForValue(2),
          chart.scales.y.getPixelForValue(0) -
          chart.scales.y.getPixelForValue(2),
          risk_color_3
        ),
        // Grün
        new Shape(
          chart.scales.x.getPixelForValue(0),
          chart.scales.y.getPixelForValue(1),
          chart.scales.x.getPixelForValue(1) -
          chart.scales.x.getPixelForValue(0),
          chart.scales.y.getPixelForValue(0) -
          chart.scales.y.getPixelForValue(1),
          risk_color_1
        )
      );

      for (let i = 0; i < areas.length; i++) {
        let area = areas[i];
        ctx.fillStyle = area.fill;
        ctx.fillRect(area.x, area.y, area.w, area.h);
      }

      ctx.restore();
    },
  };

  const chart = new Chart(ctx, {
    // Plugin für Hintergrundfarbe
    plugins: [background_canvas_plugin],
    type: "bubble",
    data: {
      datasets: datasets,
    },
    options: {
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label: label_callback,
          }
        }
      },
      layout: {
        padding: {
          left: 30,
          right: 30,
          top: 30,
          bottom: 30,
        },
      },
      responsive: true,
      maintainAspectRatio: true,
      aspectRatio: 1,
      scales: {
        y: {
          max: 4,
          min: 0,
          ticks: {
            stepSize: 1,
          },
          title: {
            display: true,
            text: label_y,
          },
        },
        x: {
          max: 4,
          min: 0,
          ticks: {
            stepSize: 1,
          },
          title: {
            display: true,
            text: label_x,
          },
        },
      },
    },
  });

  return chart;
}

function calculate_audit_risk(audit) {
  const result = {};
  audit.data.forEach((chapter) => {
    /* question counts */
    let risk_question_count = 0;
    let measure_question_count = 0;

    /* Risk Propabilities */
    let gross_risk_prop = 0;
    let gross_risk_measure = 0;
    let net_risk_measure = 0;
    let net_risk_prop = 0;

    /* find the highest number for each question and accumulate it inside chapter for both measure and probability */
    chapter.questions.forEach((question) => {
      if (question.type == 'risk') {
        risk_question_count += 1;
        const result = _find_highest_numbers(question);
        gross_risk_prop += result.prop;
        gross_risk_measure += result.measure;
      } else if (question.type === "measures") {
        measure_question_count += 1;
        const result = _find_highest_numbers(question);
        net_risk_prop += result.prop;
        net_risk_measure += result.measure;
      }
    })

    // Begrenzen der Werte auf Maximum 4 und Minimum 0
    gross_risk_prop = Math.min(_safeDivide(gross_risk_prop, risk_question_count), 4);
    gross_risk_measure = Math.min(_safeDivide(gross_risk_measure, risk_question_count), 4);
    net_risk_prop = Math.max(Math.min(_safeDivide(net_risk_prop, measure_question_count), 4), 0);
    net_risk_measure = Math.max(Math.min(_safeDivide(net_risk_measure, measure_question_count), 4), 0);


    result[chapter.id] = {
      gross_risk_prop: gross_risk_prop,
      gross_risk_measure: gross_risk_measure,
      net_risk_prop: Math.max(gross_risk_prop-net_risk_prop, 0),
      net_risk_measure: Math.max(gross_risk_measure-net_risk_measure, 0),
    };
    
  })
  return result
}

/* function to find the single highest risk score inside a question */
function _find_highest_numbers(question) {
  let highest_number_prop = 0;
  let highest_number_risk = 0;
  let measure = 0;
  let prop = 0;

  question.answers?.forEach((answerId) => {
    const choice = question.choices?.find((c) => c.id == answerId);
    
    if (choice) {
      if (choice.riskProp > highest_number_prop) {
        highest_number_prop = choice.riskProp;
        prop = (choice.riskProp*question.weight);
      } if (choice.riskMeasure > highest_number_risk) {
        highest_number_risk = choice.riskMeasure;
        measure = (choice.riskMeasure*question.weight);
      }
    }
  })

  return {
    measure: measure,
    prop: prop
  }
}

function _safeDivide(numerator, denominator, fallback = 0) {
  return denominator !== 0 ? numerator / denominator : fallback;
}

export { calculate_processing_activity, calculate_impact_assessment, calculate_scenario, create_risk_chart, calculate_gross_sources, calculate_scenarios, calculate_audit_risk };

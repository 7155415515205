<template>
    <v-card max-height="80vh">
        <v-list v-if="notifications.length > 0">
            <template v-for="(notification, index) in notifications">
                <v-list-item :key="index" @click="navigateNotification(notification.link)">
                    <v-list-item-content>
                        <v-list-item-title>{{ get_title(notification) }}</v-list-item-title>
                        <v-list-item-subtitle>{{ formatDate(notification.time) }}, {{ formatTime(notification.time) }}
                            Uhr</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <v-divider :key="'divider-' + index" v-if="index < notifications.length - 1"></v-divider>
            </template>
            <v-list-item @click="load()" v-if="items_length > notifications.length">
                <v-list-item-content>
                    <v-list-item-title>Weitere Benachrichtigungen laden</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        <v-list v-else>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('keine_benachrichtigungen') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-card>
</template>

<script>
import { File } from '@/classes/File';
import { host } from '@/plugins/axios'
import { Notification } from '@/classes/Notification'
import { formatDate, formatTime } from '@/helpers'
import { useGlobalStore } from '@/store/globalStore';
import { mapStores } from 'pinia'

export default {
    components: {
    },
    data: () => ({
        socket: null,
        notifications: [],
        items_length: 0,
        reconnectInterval: 5000, // Zeit in Millisekunden für den erneuten Verbindungsversuch
        reconnectAttempts: 0,
        maxReconnectAttempts: 3, // maximale Anzahl der Wiederverbindungsversuche
    }),
    created() {
        // Get last notifications
        this.load();

        // Create websocket connection
        this.establishWebsocket();
    },
    computed: {
        ...mapStores(useGlobalStore)
    },
    methods: {
        establishWebsocket() {
            const protocol = window.location.protocol === "https:" ? "wss" : "ws";
            this.socket = new WebSocket(protocol + "://" + host + "/ws/notifications/" + "?token=" + this.globalStore.tokens.token);

            this.socket.onmessage = (event) => {
                const notification = JSON.parse(event.data);
                this.notifications.unshift(notification);

                // Increment items length
                this.items_length += 1;

                this.$emit("notify");
            };

            this.socket.onopen = () => {
                /* console.log("WebSocket connected"); */
                this.reconnectAttempts = 0; // Verbindungsversuche zurücksetzen
            };

            this.socket.onclose = (event) => {
                console.log(`WebSocket closed: ${event.reason}`);
                this.tryReconnect();
            };

            this.socket.onerror = (error) => {
                console.error(`WebSocket error: ${error.message}`);
                this.socket.close();
            }
        },
        tryReconnect() {
            if (this.reconnectAttempts < this.maxReconnectAttempts) {
                setTimeout(() => {
                    console.log(`Attempting to reconnect... (${this.reconnectAttempts + 1}/${this.maxReconnectAttempts})`);
                    this.reconnectAttempts += 1;
                    this.establishWebsocket(); // Erneuter Verbindungsaufbau
                }, this.reconnectInterval);
            } else {
                console.error("Max reconnect attempts reached. Could not reconnect to WebSocket.");
            }
        },
        navigateNotification(link) {
            switch (link.type) {
                case 'task':
                    this.$router.push({ name: "company-tasks", params: { companyId: link.company_id, id: link.id } }).catch(() => { })
                    break;
                case 'export':
                    File.download(link.file)
                    break;
                default:
                    break;
            }

            //this.$emit("close");
        },
        get_title(notification) {
            switch (notification.link.type) {
                case 'task':
                    return this.$i18n.t('aufgaben_benachrichtigung', { name: notification.title })
                case 'export':
                    return this.$i18n.t('export_benachrichtigung', { name: notification.title })
                case 'bg_task':
                    return this.$i18n.t('done_background_task', { name: notification.title })
                case 'bg_task_inheritance':
                    return this.$i18n.t('done_background_task_inheritance', { name: notification.title, count: notification.link.company_count })
                default:
                    return notification.title;
            }
        },
        load() {
            Notification.list({ limit: 10, offset: this.notifications.length }).then((res) => {
                this.items_length = parseInt(res.data.count);
                this.notifications.push(...res.data.results);
            })
        },
        formatDate,
        formatTime
    },
};
</script>
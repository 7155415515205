import { defineStore } from 'pinia'

export const useGlobalStore = defineStore('global', {
    state: () => ({
        loading: false,
        perms: [],
        user: {},
        license: {},
        tokens: {},
        resource_labels: {},
        user_language: 'deu',
        items_per_page: 10,
    }),
    getters: {
        routeLoading: (state) => state.loading,
        get_perms: (state) => state.perms,
        get_user: (state) => state.user,
        get_license: (state) => state.license,
        get_tokens: (state) => state.tokens,
        get_resource_labels: (state) => state.resource_labels,
        get_user_language: (state) => state.user_language,
        get_items_per_page: (state) => state.items_per_page,
    },
    actions: {
        async init() {
            // initialize items per page
            this.setItemsPerPage(localStorage.getItem("items_per_page") ? parseInt(localStorage.getItem("items_per_page")) : 10);
        },
        async toogleLoading() {
            this.loading = !this.loading;
        },
        async setPerms(perms) {
            this.perms = perms
        },
        async setResourceLabels(resource_labels) {
            this.resource_labels = resource_labels;
        },
        async setLicense(license) {
            this.license = license
        },
        async setUser(user) {
            this.user = user;
        },
        async setTokens(tokens) {
            this.tokens = tokens;
        },
        async setUserLanguage(language) {
            this.user_language = language
        },
        async setItemsPerPage(value) {
            // Set local storage
            localStorage.setItem("items_per_page", value)
            this.items_per_page = value;
        },
    }
})
import { http } from "@/plugins/axios";
import Router from "@/router/index";
import qs from 'qs'

class News {
    static url = 'news/'

    constructor(
        data = {
            id: "",
            popup: false,
            start_date: null,
            end_date: null,
            name: "",
            text: "",
        }
    ) {
        this.id = data.id,
        this.popup = data.popup,
        this.start_date = data.start_date,
        this.end_date = data.end_date,
        this.name = data.name,
        this.text = data.text
    }


    static async list(params) {

        return http.get(`news/`, {
            params: params,
            paramsSerializer: params => {
                return qs.stringify(params, { arrayFormat: 'comma' })
            }
        })
    }

    static async get(newsId) {
        const company_id = Router.currentRoute.params?.companyId;

        if (company_id == undefined) {
            return;
        }
        return http.get(`companies/${company_id}/news/${newsId}/`);
    }
}

export{ News }
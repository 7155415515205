import axios from "axios";
import cookies from "js-cookie";
//import createAuthRefreshInterceptor from "axios-auth-refresh";
import { useGlobalStore } from '@/store/globalStore';
import Router from "@/router/index";

const host = process.env.NODE_ENV === "production"
  ? window.location.hostname +
  (window.location.port != "" ? ":" : "") +
  window.location.port
  : "localhost:8000";

const baseURL =
  process.env.NODE_ENV === "production"
    ? window.location.protocol +
    "//" + host
    : "http://" + host;

const http = axios.create({
  baseURL: baseURL + "/api/",
});

// Needed for session auth to send cookie with every request
if (process.env.NODE_ENV != "production") {
  http.defaults.withCredentials = true;
}

http.defaults.headers.common["X-CSRFToken"] = cookies.get("csrftoken");

http.interceptors.request.use(function (request) {
  const store = useGlobalStore();
  request.headers["Authorization"] = `Bearer ${store.tokens.token}`;
  return request;
})

http.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  /* console.log(error); */

  if (error.response?.status === 403) {
    // If Ip is not allowed redirect to access denied
    if (error.response.data?.detail == "ip_not_allowed") {
      Router.push({ name: "access-denied" });
    } else {
      // With different errors redirect to access denied page 
      if (Router.currentRoute.name !== "access-denied") {
        Router.push({ name: "access-denied" });
      }
    }
  }
  return Promise.reject(error);
});

export { http, baseURL, host };

<template>
    <HintDialog ref="dialog">
        <template v-slot:title-action>
            <v-btn icon @click="closeDialog">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>

        <!-- Übergabe des close-Buttons über einen Slot -->
        <template v-slot:close-button>
            <v-btn icon @click="closeDialog">
                <v-icon color="white">mdi-close</v-icon>
            </v-btn>
        </template>

        <template v-for="news in news">
            <v-row :key="news.id" >
                <v-col v-html="news.text"></v-col>
            </v-row>
        </template>
    </HintDialog>
</template>

  
<script>
import { useGlobalStore } from '@/store/globalStore';
import { mapStores } from 'pinia'
import { News } from '@/classes/News';

export default {
components: {
    HintDialog: () => import("@/components/common/dialogs/HintDialog.vue"),
},
data() {
    return {
        news: [],
    }
},
mounted() {
    this.loadNews();
},
computed: {
    ...mapStores(useGlobalStore)
},
methods: {
    async loadNews() {
        let allCurrentNews;
        /* Set news in globalstore*/
        News.list({'is_active': true}).then((res) => {
            allCurrentNews = res.data.results || [];
            // Gelesene Nachrichten aus dem localStorage holen und parsen
            const readNewsIds = JSON.parse(localStorage.getItem('readNewsId') || '[]');

            // Nur ungelesene Nachrichten filtern
            this.news = allCurrentNews.filter(news => !readNewsIds.includes(news.id));
            // Dialog nur öffnen, wenn es ungelesene Nachrichten gibt
            if (this.news.length > 0) {
                this.$nextTick(() => {
                    this.open()
                })
            }
        })        
    },
    open() {
        this.$refs.dialog.open();
    },
    closeDialog() {
        this.$refs.dialog.close();
        this.markNewsAsRead();
    },
    /* mark news as already read if it has been closed by user */
    markNewsAsRead() {
        // Lade die vorhandene Liste aus dem Local Storage oder initialisiere sie als leere Liste
        let readNewsIds = JSON.parse(localStorage.getItem("readNewsId"));
        // Falls der Wert im Local Storage kein Array ist, initialisiere ein leeres Array
        if (!Array.isArray(readNewsIds)) {
            readNewsIds = [];
        }

        // Füge die neuen IDs hinzu, aber nur, wenn sie noch nicht in der Liste sind
        this.news.forEach(element => {
            if (!readNewsIds.includes(element.id)) {
                readNewsIds.push(element.id);
            }
        });
        // Speichere die aktualisierte Liste zurück in den Local Storage
        localStorage.setItem("readNewsId", JSON.stringify(readNewsIds));
    }

}

};
</script>
  